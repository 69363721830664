import React, { Component } from 'react';

class Game extends Component {
  render() {
    return (
      <main className="bg">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <div className="index title">
                <div className="intro">
                  <p className="welcome">Welcom to omegathrone!</p>
                  <p>Omegathrone makes games.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Game;
