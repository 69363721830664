import React, { Component } from 'react';

class Application extends Component {
  render() {
    return (
      <main className="bg">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <h1 className="application">
                application
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="row">
                <div className="col-sm">
                  <hr className="line" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <h4 className="game-title">OmegaTip</h4>
                  <p>{'Don\'t worry about the tip. Here\'s tipping app for you!'}</p>
                  <a href="https://apps.apple.com/app/id1454428329">
                    <img className="app-badge" src="images/appstore-badge.png" alt="OmegaTip" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.Omegathrone.OmegaTip">
                    <img className="play-badge" src="images/google-play-badge.png" alt="OmegaTip" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Application;
