import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';

class Header extends Component {
  constructor() {
    super();
    this.handleClickHomeMenu = this.handleClickHomeMenu.bind(this);
    this.handleClickGameMenu = this.handleClickGameMenu.bind(this);
    this.handleClickAppMenu = this.handleClickAppMenu.bind(this);
    this.handleClickContactMenu = this.handleClickContactMenu.bind(this);
  }

  handleClickHomeMenu() {
    this.props.setView('home');
  }

  handleClickGameMenu() {
    this.props.setView('game');
  }

  handleClickAppMenu() {
    this.props.setView('application');
  }

  handleClickContactMenu() {
    this.props.setView('contact');
  }

  render() {
    const {
      handleClickHomeMenu,
      handleClickGameMenu,
      handleClickAppMenu,
      handleClickContactMenu
    } = this;
    return (
      <header>
        <Navbar collapseOnSelect bg="dark" expand="lg" variant="dark">
          <Navbar.Brand href="#home" onClick={handleClickHomeMenu}><img className="logo-img" src="../images/omegathrone-logo.png"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#home" onClick={handleClickHomeMenu}>home</Nav.Link>
              <Nav.Link href="#portfolio" onClick={handleClickGameMenu}>game</Nav.Link>
              <Nav.Link href="#about" onClick={handleClickAppMenu}>app</Nav.Link>
              <Nav.Link href="#link" onClick={handleClickContactMenu}>contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}

export default Header;
