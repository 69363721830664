import React, { Component } from 'react';

class Game extends Component {
  render() {
    return (
      <main className="bg">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <h1 className="game">
                game
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="row">
                <div className="col-sm">
                  <hr className="line" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <h4 className="game-title">OmegaMATH</h4>
                  <p>{'"THE" Ultimate and Simple Math game to help your brain keep healthy throughout enjoying, testing, or practicing simple addition, subtraction, multiplication, and division. Within 30 seconds, try to do your best to record high scores. It\'s speedy and thrilling!'}</p>
                  <a href="https://apps.apple.com/us/app/omegathrone/id1398139377">
                    <img className="app-badge" src="images/appstore-badge.png" alt="OmegaMATH" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.Omegathrone.OmegaMath">
                    <img className="play-badge" src="images/google-play-badge.png" alt="OmegaMATH" />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <hr className="line" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <h4 className="game-title">OmegaMatching</h4>
                  <p>Enjoy your memory matching gmae with your own photos</p>
                  <a href="https://apps.apple.com/us/app/omegamatching/id1479483890">
                    <img className="app-badge" src="images/appstore-badge.png" alt="OmegaMatching" />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <hr className="line" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <h4 className="game-title">OmegaSUDOKU</h4>
                  <p>Enjoy Ultimate SUDOKU anytime anywhere</p>
                  <a href="https://apps.apple.com/us/app/omegasudoku/id1483300062">
                    <img className="app-badge" src="images/appstore-badge.png" alt="OmegaSUDOKU" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.omegathrone.omegasudoku">
                    <img className="play-badge" src="images/google-play-badge.png" alt="OmegaSUDOKU" />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <hr className="line" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <h4 className="game-title">OmegaDots</h4>
                  <p>Connect two dots and fill the board to clear the level</p>
                  <a href="https://apps.apple.com/us/app/omegadots/id1489243073?ls=1">
                    <img className="app-badge" src="images/appstore-badge.png" alt="OmegaDots" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.Omegathrone.OmegaDots">
                    <img className="play-badge" src="images/google-play-badge.png" alt="OmegaDots" />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <hr className="line" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <h4 className="game-title">OmegaBrickEscape</h4>
                  <p>Move other bricks to let the yellow brick escape</p>
                  <a href="https://apps.apple.com/us/app/omegabrickescape/id1494233992?ls=1">
                    <img className="app-badge" src="images/appstore-badge.png" alt="OmegaBrickEscape" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.omegathrone.omegabrickescape">
                    <img className="play-badge" src="images/google-play-badge.png" alt="OmegaBrickEscape" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Game;
