import React from 'react';
import Header from './header';
import Footer from './footer';
import Home from './home';
import Game from './game';
import Application from './application';
import Contact from './contact';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      view: 'home'
    };
    this.setView = this.setView.bind(this);
  }

  setView(view) {
    this.setState({
      view
    });
  }

  render() {
    const { view } = this.state;
    const { setView } = this;
    let element = null;
    switch (view) {
      case 'home':
        element = (
          <Home />
        );
        break;
      case 'game':
        element = (
          <Game />
        );
        break;
      case 'application':
        element = (
          <Application />
        );
        break;
      case 'contact':
        element = (
          <Contact />
        );
        break;
    }
    return (
      <>
        <Header
          setView={setView} />
        {element}
        <Footer />
      </>
    );
  }
}

export default App;
